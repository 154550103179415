<template>
    <div id="Vendor">
        <ZnapTable ref="znapTable"
            :endpoint="endpoint"
            :tableName="tableName"
            :tableIcon="tableIcon"
            :tableDescription="tableDescription"
            :options="options"
            :optionsAddress="optionsAddress"
            :optionsType="optionsType"
            :show="false"
            :hasTabs="true"
            :tab="0"
            :testExcel="testExcel"
            :makeLoad="makeLoad"
            :tableRows="tableRows"
            :noData="noData"
            @setNoData="setNoData"
            :noDataMessage="noDataMessage"
            @setNoDataMessage="setNoDataMessage"
            :filterConditions="filterConditions"
            @setFilterConditions="setFilterConditions"
            @clearFilters="setFilterConditions"
            :loadTable="loadTable"
            @setLoadTable="setLoadTable"
            @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            @setTestExcel="$emit('setTestExcel')"
            @forceLoad="$emit('forceLoad')"
        >
            <!-- // Filters -->
            <template v-slot:filters>
                <ZnapFilters ref="znapFilters"
                    :filterComponents="options"
                    :noData="noData"
                    @setSelection="populateFilterConditions"
                    :clearFilters="clearFilters"
                    @setClearFilters="setClearFilters"
                >
                </ZnapFilters>
            </template>
        </ZnapTable>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTabs.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'Vendor',

    components: { ZnapTable, ZnapFilters },

    props: {
        testExcel: {
            type: Number,
        },
        makeLoad: {
            type: Boolean,
        }
    },

    computed: {
        endpoint() {
            return [ this.$ipVendor, 'vendor' ]
        },
    },

    data: () => ({
        tableName: 'Fornecedor',
        tableIcon: 'mdi-account-network',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [
            {
                column: 'id_vendor_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo do fornecedor',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipVendor, 'vendor-type' ]
            },
        ],

        optionsAddress: [
            {
                column: 'id_address_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo de endereço',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'address-type' ]
            },
        ],

        optionsType: [
            {
                column: 'id_vendor',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Fornecedor',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipVendor, 'vendor' ]
            },
            {
                column: 'id_address',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Descrição do endereço',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCustomer, 'address' ]
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    created() {
        this.$root.$on('exportVendor', () => {
            this.$root.$emit('exportExcel')
        })
    },

    methods: {
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>